import React, { useState } from 'react';
import { AppBar, Container, CssBaseline, Toolbar, Typography, Grid, Paper, Button, ButtonGroup, TextField } from '@mui/material';
import BGImg from '../assets/hero-background.png';
import DeliveryBoyImage from '../assets/delivery.png'; 

const categories = process.env.REACT_APP_CATEGORIES.split(',');

const ProductPage = (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');

    const applyFilters = (product) => {
      // Apply category filter
      const isCategoryMatch = selectedCategory === 'All' || product.category === selectedCategory;
  
      // Apply search filter
      const isSearchMatch =
        !searchTerm || product.name.toLowerCase().includes(searchTerm.toLowerCase());
        
      return isCategoryMatch && isSearchMatch;
    };

    const productsPerPage = 40;
    const [currentPage, setCurrentPage] = useState(1);
    const filteredProducts = selectedCategory === 'All' ? props.products.filter(applyFilters) : props.products.filter(applyFilters).filter(product => product.category === selectedCategory);
    const paginatedProducts = filteredProducts.slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage);
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    const handleCartAction = (productId) => {
      const existingProductIndex = props.cart.findIndex(item => item.id === productId);
  
      if (existingProductIndex !== -1) {
        const newCart = [...props.cart];
        newCart.splice(existingProductIndex, 1);
        props.setCart(newCart);
      } else {
        const selectedProduct = props.products.find(product => product.id === productId);
        props.setCart([...props.cart, { ...selectedProduct }]);
      }
      console.log(props.cart)
    };
  
    return (
      <div>
        <CssBaseline />
        <Container style={{ backgroundImage: `url(${BGImg})`, backgroundSize: 'cover', marginTop: '40px', marginBottom: '50px', minHeight: '50vh' }}>
        {/* Search bar */}
        <TextField
          label="Search Product"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
          <Typography variant="h6" style={{ fontWeight: 'bold', color: 'black' }}>Product Categories:</Typography>
          <ButtonGroup style={{
            backgroundColor: '#fff',
            paddingTop: '10px',
            display: 'flex',
            flexDirection: window.innerWidth < 1000 ? 'column' : 'row',
          }}>
            {categories.map(category => (
              <Button
                key={category}
                variant={selectedCategory === category ? 'contained' : 'outlined'}
                style={{ color: 'black', fontWeight: 'bold' }}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </Button>
            ))}
          </ButtonGroup>
          <Grid container spacing={3} style={{ marginTop: '20px' }}>
            {paginatedProducts.map(product => (
              <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
                <Paper style={{ padding: '10px', textAlign: 'center', border: '1px solid grey', borderRadius: '15px', backgroundColor: '#fcfcfc' }}>
                  <img src={product.image} alt={product.name} style={{ width: '120px', height: '120px', objectFit: 'contain', border: '0.5px solid grey', borderRadius: '10px' }} />
                  <Typography variant="subtitle1">{product.name}</Typography>
                  <Typography variant="body2">Quantity: {product.quantity+" "+product.unit}</Typography>
                  <Typography variant="body1">Price: ₹{product.price}</Typography>
                  {product.stock=="outstock"?
                  (<Typography style={{ backgroundColor: '#2bba32', color: 'white', fontWeight:'bold' }} variant="body1">Out Of Stock</Typography>):
                  (<Button
                    variant="contained"
                    style={{ backgroundColor: '#2bba32', color: 'white', fontWeight:'bold' }}
                    onClick={() => handleCartAction(product.id)}
                  >
                    {props.cart.some(item => item.id === product.id) ? 'Remove from Cart' : 'Add to Cart'}
                  </Button>)}
                </Paper>
              </Grid>
            ))}
          </Grid>
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index + 1}
                variant={currentPage === index + 1 ? 'contained' : 'outlined'}
                style={{ margin: '0 5px' }}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </Button>
            ))}
          </div>
          {paginatedProducts.length==0?(
          <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: 'black' }}>No product is available in this category</Typography>)
          :null}
        </Container>
      </div>
    );
  };
  
  export default ProductPage;
