import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  InputBase,
  IconButton,
  Badge,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Select from "react-select";
import {
  ShoppingCart,
  Person,
  ExitToApp,
  Assignment,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from 'react-router-dom';

const Navbar = (props) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLogout = () => {
    // Perform logout logic here
    props.setLoggedIn(false);
    navigate('/login')
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <AppBar position="sticky" style={{ backgroundColor: "#2bba32" }}>
      <Toolbar>
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <Typography
            variant="h6"
            noWrap
            style={{ fontWeight: "bold", fontStyle: "Open Sans" }}
          >
            Kanhaiya Mart
          </Typography>
        </Link>

        <div style={{ flexGrow: 1 }} />

        {window.innerWidth < 1000 ? (
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <IconButton edge="start" color="inherit" onClick={handleDrawerOpen}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={handleDrawerClose}
            >
              <List>
                <ListItem
                  onClick={handleDrawerClose}
                  component={Link}
                  to="/"
                >
                  <ListItemText primary="Home" />
                </ListItem>
                <ListItem
                  onClick={handleDrawerClose}
                  component={Link}
                  to="/products"
                >
                  <ListItemText primary="Products" />
                </ListItem>
                <ListItem
                  onClick={handleDrawerClose}
                  component={Link}
                  to="/trackshipping"
                >
                  <ListItemText primary="Track Shipping" />
                </ListItem>

                <ListItem
                  onClick={handleDrawerClose}
                  component={Link}
                  to="/shoppingcart"
                >
                  <IconButton color="inherit">
                    <Badge badgeContent={props.cartItemCount} color="error">
                      <ShoppingCart />
                    </Badge>
                  </IconButton>
                </ListItem>
                {props.isLoggedIn ? (
                  <>
                    <ListItem
                      onClick={handleDrawerClose}
                      component={Link}
                      to="/myorders"
                    >
                      <ListItemText primary="My Order" />
                    </ListItem>
                    <ListItem onClick={handleDrawerClose}>
                      <IconButton color="inherit" onClick={handleLogout}>
                        <ExitToApp />
                      </IconButton>
                    </ListItem>
                  </>
                ) : (
                  <ListItem
                    onClick={handleDrawerClose}
                    component={Link}
                    to="/login"
                  >
                    <ListItemText primary="Login/Signup" />
                  </ListItem>
                )}
              </List>
            </Drawer>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Link
              to="/"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button
                color="inherit"
                style={{ fontWeight: "bold", fontStyle: "Open Sans" }}
              >
                Home
              </Button>
            </Link>
            <Link
              to="/products"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button
                color="inherit"
                style={{ fontWeight: "bold", fontStyle: "Open Sans" }}
              >
                Products
              </Button>
            </Link>
            <Link
              to="/trackshipping"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button
                color="inherit"
                style={{ fontWeight: "bold", fontStyle: "Open Sans" }}
              >
                Track Shipping
              </Button>
            </Link>

            <Link
              to="/shoppingcart"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <IconButton color="inherit">
                <Badge badgeContent={props.cartItemCount} color="error">
                  <ShoppingCart />
                </Badge>
              </IconButton>
            </Link>

            {props.isLoggedIn ? (
              <>
                <Link
                  to="/myorders"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {/* <IconButton color="inherit">
                  <Assignment />
                </IconButton> */}
                  <Button
                    color="inherit"
                    style={{ fontWeight: "bold", fontStyle: "Open Sans" }}
                  >
                    My Orders
                  </Button>
                </Link>
                <IconButton color="inherit" onClick={handleLogout}>
                  <ExitToApp />
                </IconButton>
              </>
            ) : (
              <Link
                to="/login"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <IconButton color="inherit">
                  <Person />
                </IconButton>
              </Link>
            )}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
