import React, { useState } from 'react';
import { TextField, Button, Typography, Container } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BGImg from '../assets/hero-background.png';

const ResetPasswordPage = () => {
  const [tempPassword, setTempPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = () => {
    // Check if fields are not empty
    if (!tempPassword || !newPassword) {
      setError('Please fill in all the fields.');
      return;
    }

    // Assuming you have email_id in the URL params
    const emailId = new URLSearchParams(window.location.search).get('email_id');

    // Make Axios request to the backend
    axios.post('https://api.kanhaiyamart.com/resetpassword', {
      email_id: emailId,
      temporary_password: tempPassword,
      new_password: newPassword,
    })
      .then(response => {
        // Handle successful response
        if(response.status==200){
          window.alert(response.data.message);
          if(response.data.message=="Password Reset Successful!"){
          navigate('/login')}
        }
        else{
            window.alert(response.data.message);
        }
      })
      .catch(error => {
        // Handle error
        console.error('Error resetting password:', error);
      });
  };

  return (
    <Container maxWidth="sm" style={{marginTop:'50px', backgroundImage: `url(${BGImg})`, backgroundSize: 'cover',
    backgroundPosition: 'center',minHeight: '50vh'}}>
      <Typography variant={window.innerWidth<1000? "h5":"h4"} align="center" gutterBottom>
        Reset Password
      </Typography>
      <TextField
        label="Temporary Password"
        fullWidth
        margin="normal"
        variant="outlined"
        value={tempPassword}
        onChange={(e) => setTempPassword(e.target.value)}
      />
      <TextField
        label="New Password"
        fullWidth
        margin="normal"
        variant="outlined"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      {error && (
        <Typography variant="body2" color="error" gutterBottom>
          {error}
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        style={{ backgroundColor: '#2bba32', marginTop: '16px', fontWeight:"bold" }}
        onClick={handleResetPassword}
      >
        Submit
      </Button>
    </Container>
  );
};

export default ResetPasswordPage;
