import React from 'react';
import { Container, Typography } from '@mui/material';

const TermsAndConditions = () => {
    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
                Terms and Conditions
            </Typography>
            <Typography paragraph>
                Please read these Terms and Conditions carefully before using our website (the "Service") operated by [Your Company Name] ("us", "we", or "our").
            </Typography>
            <Typography paragraph>
                Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
            </Typography>
            <Typography variant="h6" gutterBottom>
                1. Acceptance of Terms
            </Typography>
            <Typography paragraph>
                By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. Privacy Policy
            </Typography>
            <Typography paragraph>
                Your privacy is important to us. Please review our Privacy Policy, which also governs your use of the Service, to understand our practices.
            </Typography>
            <Typography variant="h6" gutterBottom>
                3. Product Information
            </Typography>
            <Typography paragraph>
                We strive to provide accurate product descriptions and pricing information, but we do not warrant that any product description or pricing is accurate, complete, reliable, current, or error-free.
            </Typography>
            <Typography variant="h6" gutterBottom>
                4. Ordering and Payment
            </Typography>
            <Typography paragraph>
                Orders are subject to availability and acceptance. We reserve the right to refuse or cancel orders at any time, including if there are errors in pricing or product information.
            </Typography>
            <Typography variant="h6" gutterBottom>
                5. Delivery
            </Typography>
            <Typography paragraph>
                Delivery times may vary and are not guaranteed. We are not responsible for delays in delivery caused by events beyond our control.
            </Typography>
            <Typography variant="h6" gutterBottom>
                6. Returns and Refunds
            </Typography>
            <Typography paragraph>
                Please review our Returns and Refunds Policy for information on returning products and receiving refunds.
            </Typography>
            <Typography variant="h6" gutterBottom>
                7. User Accounts
            </Typography>
            <Typography paragraph>
                You may be required to create an account to access certain features of the Service. You are responsible for maintaining the confidentiality of your account and password.
            </Typography>
            <Typography variant="h6" gutterBottom>
                8. Prohibited Activities
            </Typography>
            <Typography paragraph>
                You may not use the Service for any illegal or unauthorized purpose. You agree to comply with all applicable laws and regulations.
            </Typography>
            <Typography variant="h6" gutterBottom>
                9. Intellectual Property
            </Typography>
            <Typography paragraph>
                All content included on the Service, such as text, graphics, logos, images, and software, is the property of [Your Company Name] or its licensors and is protected by copyright laws.
            </Typography>
            <Typography variant="h6" gutterBottom>
                10. Limitation of Liability
            </Typography>
            <Typography paragraph>
                In no event shall [Your Company Name] be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or goodwill.
            </Typography>
        </Container>
    );
};

export default TermsAndConditions;
