import React, { useState } from 'react';
import { Typography, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BGImg from '../assets/hero-background.png';

const TrackingPage = () => {
  const [shipmentId, setShipmentId] = useState('');
  const navigate = useNavigate();

  const handleShipmentIdChange = (event) => {
    setShipmentId(event.target.value);
  };

  const handleGetStatusClick = async() => {
    if(shipmentId==''){
      window.alert("Enter Order ID")
    }
    else{
    try {
      const response = await axios.post('https://api.kanhaiyamart.com/trackshipping', {
        shipmentId: shipmentId,
      });

      if (response.status === 200) {
      const { status_code } = response.data;

      // Navigate to the delivery-status.js page with the delivery status as a parameter
      navigate(`/deliverytracking?status=${status_code}&shipmentId=${shipmentId}`);
      }
      else{
        window.alert(response.data.message);
      }

    } catch (error) {
      console.error('Error fetching status:', error.response.data);
      // Handle the error, show an error message, etc.
    }
  }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: window.innerWidth < 1000 ? '45%': '5%', marginBottom: '50px', backgroundImage: `url(${BGImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight:'50vh' }}>
      <Typography variant={window.innerWidth < 1000 ? "h5":"h4"} component="div" fontWeight="bold" gutterBottom>
        Track Shipping
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: window.innerWidth < 1000 ? '10%': '3%'}}>
        <TextField
          label="Please provide your order ID"
          variant="outlined"
          size="small"
          value={shipmentId}
          onChange={handleShipmentIdChange}
          style={{ marginBottom: window.innerWidth < 1000 ? '10%': '3%', width: window.innerWidth < 1000 ? '90%':'50%' }}
        />
        <Button
          variant="contained"
          style={{ backgroundColor: '#2bba32', color: '#fff', width: window.innerWidth < 1000 ? '90%':'50%', marginBottom: window.innerWidth < 1000 ? '50%': '5%', fontSize: window.innerWidth < 1000 ? '12px':'', fontWeight:"bold" }}
          onClick={handleGetStatusClick}
        >
          Get Shipment Status
        </Button>
      </div>
    </div>
  );
};

export default TrackingPage;
