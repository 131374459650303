// ShippingPolicy.js

import React from 'react';
import { Container, Typography } from '@mui/material';

const ShippingPolicy = () => {
    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
                Shipping Policy
            </Typography>
            <Typography paragraph>
                We offer shipping within Uttar Pradesh. Please note that we currently do not offer international shipping.
            </Typography>
            <Typography paragraph>
                Orders are typically processed and shipped within 1-2 business days. Once your order has been shipped, you will receive a shipping confirmation email with tracking information.
            </Typography>
            <Typography paragraph>
                Shipping rates are calculated based on the weight of your order and the shipping destination. You can view the shipping costs at checkout before placing your order.
            </Typography>
            <Typography paragraph>
                We offer free shipping on orders over 1000. For orders below this threshold, shipping charges will apply.
            </Typography>
            <Typography paragraph>
                Delivery times may vary depending on your location and shipping method selected at checkout. Estimated delivery times will be provided during the checkout process.
            </Typography>
            <Typography paragraph>
                We are not responsible for any delays in delivery caused by unforeseen circumstances, such as severe weather, natural disasters, or carrier delays.
            </Typography>
            <Typography paragraph>
                If you have any questions about shipping or need assistance with your order, please contact our customer service team at kanhaiyamart01@gmail.com.
            </Typography>
        </Container>
    );
};

export default ShippingPolicy;
