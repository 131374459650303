// DeliveryTrackingPage.js
import React, { useState } from 'react';
import { Container, Paper, Typography, LinearProgress, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import BGImg from '../assets/hero-background.png';

const deliveryStatuses = [
  'order placed',
  'packing',
  'out for delivery',
  'delivered',
];

const DeliveryTrackingPage = () => {
  // Use the useLocation hook to get access to the query parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentStatus = searchParams.get('status');
  // const [currentStatus, setCurrentStatus] = useState(status);
  const orderNumber = searchParams.get('shipmentId');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="md" style={{ marginTop: '4rem', marginBottom :'50px', backgroundImage: `url(${BGImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '50vh' }}>
      <Paper elevation={3} style={{ padding: '2rem' }}>
        {/* Checkpoint 1: Display Order Number on the next line */}
        <Typography variant={window.innerWidth<1000? "h6":"h5"} gutterBottom>
          Delivery Tracking
        </Typography>
        <Typography variant={window.innerWidth<1000? "subtitle1":"h6"} gutterBottom>
          Order ID: {orderNumber}
        </Typography>

        {/* Checkpoint 2: Display Linear Progress */}
        {currentStatus!=4? (<LinearProgress
          variant="determinate"
          value={(currentStatus / (deliveryStatuses.length - 1)) * 100}
          sx={{ marginBottom: '2rem', marginTop: '1rem' }}
        />):null}

        {/* Checkpoint 3: Display Current Status */}
        <Typography variant={window.innerWidth<1000? "subtitle1":"h6"} gutterBottom>
          Current Status: {currentStatus==4? "Cancelled": deliveryStatuses[currentStatus.toLowerCase()]}
        </Typography>

        {/* Checkpoint 4: Display Status Options */}
        {currentStatus!=4 && window.innerWidth>1000 ?
        (<div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between' }}>
          {deliveryStatuses.map((status, index) => (
            <div
              key={index}
              style={{
                textAlign: 'center',
                fontWeight: index === currentStatus ? 'bold' : 'normal',
                color: index === currentStatus ? 'blue' : 'inherit',
                marginBottom: isMobile ? '0.5rem' : '0',
                pointerEvents: 'none', // Make the button not clickable
              }}
            >
              {status}
            </div>
          ))}
        </div>):null}
      </Paper>
    </Container>
  );
};

export default DeliveryTrackingPage;
