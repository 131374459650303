// Footer.js
import React from 'react';
import { Container, Grid, Typography, Link } from '@mui/material';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#000', color: '#fff', padding: '3rem 0 2rem', width: '100%', marginTop:'12%' }}>
      <Container>
        <Grid container spacing={3}>
          {/* Column 1 */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant={window.innerWidth<1000? "subtitle1":"h6"} gutterBottom>
              Kanhaiya Mart
            </Typography>
            <Typography variant="body2">
              Your one-stop solution for all your grocery needs.
            </Typography>
          </Grid>

          {/* Column 2 */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant={window.innerWidth<1000? "subtitle1":"h6"} gutterBottom>
              Quick Links
            </Typography>
            <Link href="/" color="inherit" underline="hover">
              Home
            </Link>
            <br />
            <Link href="/products" color="inherit" underline="hover">
              Products
            </Link>
            <br />
            <Link href="/trackshipping" color="inherit" underline="hover">
              Track Shipping
            </Link>
            <br />
                        <Link href="/privacy" color="inherit" underline="hover">
              Privacy Policy
            </Link>
            <br />
            <Link href="/return" color="inherit" underline="hover">
              Return Policy
            </Link>
            <br />
            <Link href="/shipping" color="inherit" underline="hover">
              Shipping Policy
            </Link>
            <br />
            <Link href="/terms&conditions" color="inherit" underline="hover">
              Terms & Conditions
            </Link>
            <br />
            {/* <Link href="#" color="inherit" underline="hover">
              Contact Us
            </Link> */}
          </Grid>

          {/* Column 3 */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant={window.innerWidth<1000? "subtitle1":"h6"} gutterBottom>
              Contact Information
            </Typography>
            <Typography variant="body2">
              Address: Ground Floor Bankepur, Sultanpur, Uttar Pradesh, 227808
            </Typography>
            <Typography variant="body2">
              Phone: 8934901321
            </Typography>
            <Typography variant="body2">
              Email: kanhaiyamart01@gmail.com
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
