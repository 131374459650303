import React,{useState,useEffect,useLayoutEffect} from 'react';
import { BrowserRouter,Routes,Route,useHistory,Link} from 'react-router-dom';
import axios from 'axios';
import Login from './components/login-signup/login';
import SignUp from './components/login-signup/signup';
import Navbar from './components/navbar/navbar';
import Home from './components/home/home';
import ProductPage from './components/products/products';
import TrackingPage from './components/track-shippment/track';
import Footer from './components/footer/footer';
import CartPage from './components/cart/cart';
import CheckoutPage from './components/address/address';
import DeliveryTrackingPage from './components/delivery/delivery-status';
import OrderSuccessPage from './components/order-successful/order-successful';
import OrdersPage from './components/my-orders/my-orders';
import ForgetPassword from './components/forget-password/forget-password';
import AdminNavbar from './components/admin-panel/navbar';
import AdminLogin from './components/admin-panel/login';
import MainPage from './components/admin-panel/main-page';
import AddProductPage from './components/admin-panel/addproduct';
import AdminOrdersPage from './components/admin-panel/orders';
import AdminProductPage from './components/admin-panel/products';
import UpdateProductPage from './components/admin-panel/update-product';
import UpdateDeliveryStatusPage from './components/admin-panel/update-delivery-status';
import ResetPasswordPage from './components/forget-password/reset';
import PrivacyPolicy from './components/footer/privacy-policy';
import ReturnPolicy from './components/footer/return';
import ShippingPolicy from './components/footer/shipping';
import TermsAndConditions from './components/footer/terms&conditions';

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [adminlogin, setadminlogin] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    // Function to fetch products based on category
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://api.kanhaiyamart.com/products');

        // Set the fetched products in the state
        if(response.status==200){
        setProducts(response.data.products);
        }
        else{
          console.log(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching products:', error.response.data);
        // Handle the error, show an error message, etc.
      }
    };

    // Call the fetchProducts function when the component mounts or when the category changes
    fetchProducts();
  }, []); // Dependency array ensures the effect runs when the category changes

  return (
    < BrowserRouter>
    <Routes>
    <Route path="/" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><Home/><Footer/></>} />
    <Route path="/privacy" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><PrivacyPolicy/><Footer/></>} />
    <Route path="/return" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><ReturnPolicy/><Footer/></>} />
    <Route path="/shipping" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><ShippingPolicy/><Footer/></>} />
    <Route path="/terms&conditions" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><TermsAndConditions/><Footer/></>} />
    <Route path="/products" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><ProductPage products={products} cart={cart} setCart={setCart}/><Footer/></>} />
    <Route path="/trackshipping" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><TrackingPage/><Footer/></>} />
    <Route path="/shoppingcart" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><CartPage cart={cart} setCart={setCart} cartItemCount={cart.length} isLoggedIn={isLoggedIn}/><Footer/></>} />
    <Route path="/checkout" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><CheckoutPage cart={cart} setCart={setCart} userInfo={userInfo}/><Footer/></>} />
    <Route path="/deliverytracking" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><DeliveryTrackingPage/><Footer/></>} />
    <Route path="/ordersuccess" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><OrderSuccessPage/><Footer/></>} />
    <Route path="/myorders" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><OrdersPage userInfo={userInfo}/><Footer/></>} />
    <Route path="/login" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><Login setLoggedIn={setLoggedIn} cartItemCount={cart.length} setUserInfo={setUserInfo}/><Footer/></>} />
    <Route path="/signup" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><SignUp setLoggedIn={setLoggedIn} cartItemCount={cart.length} setUserInfo={setUserInfo}/><Footer/></>} />
    <Route path="/forgetpassword" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><ForgetPassword/><Footer/></>} />
    <Route path="/resetpassword" element={<><Navbar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} cartItemCount={cart.length}/><ResetPasswordPage/><Footer/></>} />
    <Route path="/adminlogin" element={<><AdminNavbar adminlogin={adminlogin} setadminlogin={setadminlogin}/><AdminLogin setadminlogin={setadminlogin}/></>} />
    <Route path="/admin-main-page" element={adminlogin ? (<><AdminNavbar adminlogin={adminlogin} setadminlogin={setadminlogin} /><MainPage /></>) : null} />
    <Route path="/admin-add-product" element={adminlogin ? (<><AdminNavbar adminlogin={adminlogin} setadminlogin={setadminlogin}/><AddProductPage/></>) : null} />
    <Route path="/admin-show-orders" element={adminlogin ? (<><AdminNavbar adminlogin={adminlogin} setadminlogin={setadminlogin}/><AdminOrdersPage/></>) : null} />
    <Route path="/admin-show-products" element={adminlogin ? (<><AdminNavbar adminlogin={adminlogin} setadminlogin={setadminlogin}/><AdminProductPage/></>) : null} />
    <Route path="/admin-update-product" element={adminlogin ? (<><AdminNavbar adminlogin={adminlogin} setadminlogin={setadminlogin}/><UpdateProductPage/></>) : null} />
    <Route path="/admin-update-deliverystatus" element={adminlogin ? (<><AdminNavbar adminlogin={adminlogin} setadminlogin={setadminlogin}/><UpdateDeliveryStatusPage/></>) : null} />
    </Routes>
    </BrowserRouter>
    );
}

export default App;
