import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const categories = process.env.REACT_APP_CATEGORIES.split(',');
const units = process.env.REACT_APP_UNITS.split(',');

const AddProductPage = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState('');
  const [productName, setProductName] = useState('');
  const [qunatitychange, setQunatitychange] = useState('');
  const [price, setPrice] = useState('');
  const [unit, setUnit] = useState('');
  const [image, setImage] = useState(null);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setQunatitychange(event.target.value);
  };


  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const handleImageChange = (event) => {
    // Assuming a single file is selected
    const selectedImage = event.target.files[0];
        // Convert image to base64 string
        if (selectedImage) {
            const reader = new FileReader();
      
            reader.onloadend = () => {
              const base64String = reader.result;
      
              // Update the image state with the base64 string
              setImage(base64String);
            };
      
            reader.readAsDataURL(selectedImage);
          }
  };

  const handleAddProduct = async () => {
    // Perform Axios request to /addproduct endpoint with the provided data

    const formData = new FormData();
    formData.append('category', category);
    formData.append('productName', productName);
    formData.append('price', price);
    formData.append('unit', unit);
    formData.append('changequantity', qunatitychange);
    formData.append('image', image);

    console.log(image)

    try {
        const response = await axios.post('https://api.kanhaiyamart.com/addproduct', formData);
  
        // Navigate to the delivery-status.js page with the delivery status as a parameter
        if (response.status === 201) {
            window.alert('Product added successfully!');
            navigate(`/admin-main-page`);
        }
        else{
            window.alert('Error Occurred!');
        }
        
  
      } catch (error) {
        console.error('Error fetching status:', error.response.data);
        // Handle the error, show an error message, etc.
      }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Add a New Product
      </Typography>
      <FormControl fullWidth margin="normal">
        <InputLabel>Category</InputLabel>
        <Select value={category} onChange={handleCategoryChange}>
        {categories.map((category, index) => (
          <MenuItem key={index} value={category}>
            {category}
          </MenuItem>
        ))}
          {/* Add more categories as needed */}
        </Select>
      </FormControl>
      <TextField
        label="Product Name"
        fullWidth
        margin="normal"
        value={productName}
        onChange={handleProductNameChange}
      />
      <TextField
        label="Price per Unit"
        fullWidth
        margin="normal"
        value={price}
        type="number"
        onChange={handlePriceChange}
      />
      <TextField
        label="change in quantity"
        fullWidth
        margin="normal"
        value={qunatitychange}
        type="number"
        onChange={handleQuantityChange}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Unit</InputLabel>
        <Select value={unit} onChange={handleUnitChange}>
        {units.map((unit, index) => (
          <MenuItem key={index} value={unit}>
            {unit}
          </MenuItem>
        ))}
          {/* Add more units as needed */}
        </Select>
      </FormControl>
      <Input
        type="file"
        onChange={handleImageChange}
        fullWidth
        margin="normal"
      />
      <Button
        variant="contained"
        style={{ backgroundColor: '#2bba32', marginTop: '20px', fontWeight:'bold' }}
        onClick={handleAddProduct}
      >
        Add Product
      </Button>
    </Container>
  );
};

export default AddProductPage;
