import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, List, ListItem, ListItemText, Divider, Paper, Button } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminOrdersPage = () => {

    const navigate = useNavigate();
    const [orderList, setorderList] = useState([])
    const [filteredOrderList, setFilteredOrderList] = useState([]);
    const [refreshPage, setRefreshPage] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Function to fetch orders
        const fetchmyorders = async () => {
          try {
            const response = await axios.get('https://api.kanhaiyamart.com/adminfetchorders');
    
            if(response.status==200){
            // Set the fetched orders list in the state
            setorderList(response.data.orders);
            }
            else{
                console.log("Error Occurred in fetching Orders!")
            }
          } catch (error) {
            console.error('Error fetching order List:', error.response.data);
            // Handle the error, show an error message, etc.
          }
        };
    
        // Call the fetchProducts function when the component mounts or when the category changes
        fetchmyorders();
        setRefreshPage(false);
      }, [refreshPage]); // Dependency array ensures the effect runs when the category changes

      useEffect(() => {
        // Filter orders based on the search term
        const filteredOrders = orderList.filter(
          (order) => order.order_id.toString().includes(searchTerm)
        );
        setFilteredOrderList(filteredOrders);
      }, [searchTerm, orderList]);

      const handleGetStatusClick = async(orderId) => {
        try {
          const response = await axios.post('https://api.kanhaiyamart.com/admincancelorders', {
            order_id: orderId
          });
          if (response.data.success) {
            window.alert('Order Cancelled successfully!');
          }
          else{
            window.alert('Error Occurred!');
          }
          setRefreshPage(true);
        } catch (error) {
          console.error('Error fetching status:', error.response.data);
          // Handle the error, show an error message, etc.
        }
      };

      const handleUpdateDeliveryStatusClick=(order_id, status)=>{

      // Navigate to the delivery-status.js page with the delivery status as a parameter
      navigate(`/admin-update-deliverystatus?order_id=${order_id}&status=${status}`);
      }
    
  return (
    <div style={{ textAlign: 'center', padding: '20px', marginBottom: orderList.length==0 ? '22%':'5%' }}>
      <Typography variant="h4" gutterBottom>
        All Orders
      </Typography>
      <TextField
        label="Search Order ID"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '10px' }}
      />
      {filteredOrderList.map(order => (
        <Paper key={order.order_id} style={{ margin: '20px auto', padding: '20px', maxWidth: '600px', backgroundColor: '#fcfcfc' }}>
          <Typography variant="h6" gutterBottom>
            Order ID: {order.order_id}
          </Typography>
          <List>
            {order.product_list.map(product => (
              <ListItem key={product.name} style={{ justifyContent: 'space-between' }}>
                <ListItemText primary={product.name} secondary={`Quantity: ${product.quantity+" "+product.unit}`} />
              </ListItem>
            ))}
          </List>
          <Divider style={{ margin: '16px 0' }} />
          <Typography variant="subtitle1" gutterBottom>
            Total Amount: ₹{order.user_name}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Total Amount: ₹{order.total_amount}
          </Typography>
          <Typography variant="subtitle1">
            Status: {order.delivery_status ? order.delivery_status : 'Order Placed'}
          </Typography>
          <Typography variant="subtitle1">
            Payment Method: {order.mode_of_payment}
          </Typography>
          <Typography variant="subtitle1">
            Address: {order.building_number+" "+order.street+" "+order.area+" "+order.city+" "+order.state+" "+order.pincode}
          </Typography>
          <Typography variant="subtitle1">
            Phone Number: {order.phone}
          </Typography>
          {order.delivery_status.toLowerCase() != 'cancelled' ? (
          <Button
          variant="contained"
          style={{ backgroundColor: '#2bba32', color: '#fff', width: window.innerWidth < 1000 ? '90%':'50%', marginBottom: window.innerWidth < 1000 ? '50%': '5%', fontSize: window.innerWidth < 1000 ? '12px':'', fontWeight:"bold" }}
          onClick={() => handleUpdateDeliveryStatusClick(order.order_id, order.delivery_status)}
          >
          Update Delivery Status
          </Button>
          ):null}
          <br/>
          {order.delivery_status.toLowerCase() != 'delivered' && order.delivery_status.toLowerCase() != 'cancelled' ? (
          <Button
          variant="contained"
          style={{ backgroundColor: 'red', color: '#fff', width: window.innerWidth < 1000 ? '90%':'50%', marginBottom: window.innerWidth < 1000 ? '50%': '5%', fontSize: window.innerWidth < 1000 ? '12px':'', fontWeight:"bold" }}
          onClick={() => handleGetStatusClick(order.order_id)}
          >
          Cancel Order
          </Button>
          ):null}
        </Paper>
      ))}
        {filteredOrderList.length == 0 ? (
        <Paper style={{ margin: '20px auto', padding: '20px', maxWidth: '600px', backgroundColor: '#fcfcfc' }}>
        <Typography variant="h5" gutterBottom style={{marginTop:'2%', color:'grey'}}>
           Empty(No order history)
        </Typography>
        </Paper>
      ) : null}
    </div>
  );
};

export default AdminOrdersPage;
