import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BGImg from '../assets/hero-background.png'; // Adjust the path based on your project structure
import axios from 'axios';
import { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp(props) {
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  const navigate = useNavigate();

  const handleSignup = () => {
    // Your login logic here
  
    // Assuming the login is successful
    setOpen(true);
  
    // Redirect after 2 seconds
    setTimeout(() => {
      setOpen(false);
      if(props.cartItemCount!=0){
        navigate('/shoppingcart');
      }
      else{
        navigate('/products');
      }
    }, 1000);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async(event) => {
    event.preventDefault();

    // Validation
    let valid = true;
    const newErrors = { firstName: '', lastName: '', email: '', password: '' };

    const formData = new FormData(event.currentTarget);

    if(formData.get('firstName').trim() === '') {
      newErrors.firstName = 'First Name is required';
      valid = false;
    }

    if (formData.get('lastName').trim() === '') {
      newErrors.lastName = 'Last Name is required';
      valid = false;
    }

    if (!validateEmail(formData.get('email'))) {
      newErrors.email = 'Enter Correct Email';
      valid = false;
    }

    if (formData.get('password').length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
      valid = false;
    }

    if (valid) {

    const data = new FormData(event.currentTarget);
    try {
      const response = await axios.post('https://api.kanhaiyamart.com/signup', {
        firstname: data.get('firstName'),
        lastname: data.get('lastName'),
        email: data.get('email'),
        password: data.get('password'),
      });

      // Handle the response based on your backend's response
      if (response.data.message == "Signup successful") {
      props.setLoggedIn(true);
      props.setUserInfo({'user_id':response.data.user_id,'firstName':response.data.firstName,'lastName':response.data.lastName});
      handleSignup()
      }
      else{
        window.alert(response.data.message)
      }

    } catch (error) {
      console.error('Login failed:', error.response.data);
      // Handle login failure, show error message, etc.
    }
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });

  } else {
    // Update errors and display error messages
    setErrors(newErrors);
  }
  }

  // const handleChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  return (
    // <ThemeProvider theme={defaultTheme}>
    <div
    style={{
      backgroundImage: `url(${BGImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '70vh',
    }}
  >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  error={!!errors.password}
                  helperText={errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                /> */}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: '#2bba32',
                '&:hover': {
                  backgroundColor: '#2bba32', // Set the hover color to be the same as the default background color
                },
              }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" variant="body2" style={{fontSize:'14px'}}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={2000} // Duration in milliseconds
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="success">
          Successfully Signed up
        </MuiAlert>
      </Snackbar>
      </div>
    // </ThemeProvider>
  );
}