import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const UpdateProductPage = () => {
    const navigate = useNavigate();
  const location = useLocation();
  const [updatedPrice, setUpdatedPrice] = useState(''); // State for updated price

  const searchParams = new URLSearchParams(location.search);
  const product_id = searchParams.get('product_id');

  const handlePriceChange = (event) => {
    setUpdatedPrice(event.target.value);
  };

  const handleOutStock = async () => {
    // Make an Axios request to update the product price
    try {
      await axios.post('https://api.kanhaiyamart.com/updateoutstock', { product_id });
      // Handle success, show an alert or navigate to another page
      window.alert('Product Stock updated successfully!');
      navigate('/admin-show-products')
    } catch (error) {
      console.error('Error updating product price:', error.response.data);
      // Handle the error, show an error message, etc.
    }
  };
  const handleInStock = async () => {
    // Make an Axios request to update the product price
    try {
      await axios.post('https://api.kanhaiyamart.com/updateinstock', { product_id });
      // Handle success, show an alert or navigate to another page
      window.alert('Product Stock updated successfully!');
      navigate('/admin-show-products')
    } catch (error) {
      console.error('Error updating product price:', error.response.data);
      // Handle the error, show an error message, etc.
    }
  };

  const handleUpdatePrice = async () => {
    // Make an Axios request to update the product price
    try {
      await axios.post('https://api.kanhaiyamart.com/updateproductprice', { product_id, updatedPrice });
      // Handle success, show an alert or navigate to another page
      window.alert('Product price updated successfully!');
      navigate('/admin-show-products')
    } catch (error) {
      console.error('Error updating product price:', error.response.data);
      // Handle the error, show an error message, etc.
    }
  };

  const handleDeleteProduct = async () => {
    // Make an Axios request to delete the product
    try {
      await axios.post('https://api.kanhaiyamart.com/deleteproduct', { product_id });
      // Handle success, show an alert or navigate to another page
      window.alert('Product deleted successfully!');
      navigate('/admin-show-products')
    } catch (error) {
      console.error('Error deleting product:', error.response.data);
      // Handle the error, show an error message, etc.
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Update the Product
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Product ID: {product_id}
      </Typography>
      <TextField
        label="Updated Price"
        fullWidth
        margin="normal"
        value={updatedPrice}
        type="number"
        onChange={handlePriceChange}
      />
      <Button
        variant="contained"
        style={{ backgroundColor: '#2bba32', marginTop: '20px', fontWeight:'bold' }}
        onClick={handleUpdatePrice}
      >
        Update Price
      </Button>
      <Button
        variant="contained"
        style={{ backgroundColor: 'red', marginTop: '20px', marginLeft: '10px', fontWeight:'bold' }}
        onClick={handleDeleteProduct}
      >
        Delete Product
      </Button>
      <Button
        variant="contained"
        style={{ backgroundColor: 'red', marginTop: '20px', marginLeft: '10px', fontWeight:'bold' }}
        onClick={handleOutStock}
      >
        Make Out of Stock
      </Button>
      <Button
        variant="contained"
        style={{ backgroundColor: 'red', marginTop: '20px', marginLeft: '10px', fontWeight:'bold' }}
        onClick={handleInStock}
      >
        Make in the Stock
      </Button>
    </Container>
  );
};

export default UpdateProductPage;
