import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

const UpdateDeliveryStatusPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const order_id = searchParams.get('order_id');
    const currentStatus = searchParams.get('status');
  const [status, setStatus] = useState(''); // State for selected delivery status

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleUpdateStatus = async () => {
    // Make an Axios request to update delivery status
    try {
      await axios.post('https://api.kanhaiyamart.com/updatedeliverystatus', { order_id, status });
      // Handle success, show an alert or navigate to another page
      window.alert('Delivery status updated successfully!');
      navigate('/admin-main-page')
    } catch (error) {
      console.error('Error updating delivery status:', error.response.data);
      // Handle the error, show an error message, etc.
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Update Delivery Status
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Order ID: {order_id}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
       Current Status: {currentStatus}
      </Typography>
      <FormControl fullWidth margin="normal">
        <InputLabel>Delivery Status</InputLabel>
        <Select value={status} onChange={handleStatusChange}>
          <MenuItem value="Packing">Packing</MenuItem>
          <MenuItem value="Out for Delivery">Out for Delivery</MenuItem>
          <MenuItem value="Delivered">Delivered</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        style={{ backgroundColor: '#2bba32', marginTop: '20px', fontWeight:'bold' }}
        onClick={handleUpdateStatus}
      >
        Update Delivery Status
      </Button>
    </Container>
  );
};

export default UpdateDeliveryStatusPage;
