// CheckoutPage.js

import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BGImg from '../assets/hero-background.png';

const CheckoutPage = (props) => {

    const [formData, setFormData] = useState({
    firstName: props.userInfo.firstName? props.userInfo.firstName:'',
    lastName: props.userInfo.lastName? props.userInfo.lastName:'',
    phoneNumber: props.userInfo.phoneNumber? props.userInfo.phoneNumber:'',
    city: props.userInfo.city? props.userInfo.city:'',
    area: props.userInfo.area? props.userInfo.area:'',
    street: props.userInfo.street? props.userInfo.street:'',
    buildingNumber: props.userInfo.buildingNumber? props.userInfo.buildingNumber:'',
    State: props.userInfo.State? props.userInfo.State:'',
    Pincode: props.userInfo.Pincode? props.userInfo.Pincode:''
  });

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [paymentMode, setPaymentMode] = useState('Cash');

  const handlePaymentModeChange = (event) => {
    setPaymentMode(event.target.value);
  };

  // const validateEmail = (email) => {
  //   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return regex.test(email);
  // };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\d{10}$/;
    return regex.test(phoneNumber);
  };

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    let newErrors = { ...errors };

    // if (field === 'email') {
    //   newErrors.email = validateEmail(value) ? '' : 'Invalid email address';
    // } 
    if (field === 'phoneNumber') {
      newErrors.phoneNumber = validatePhoneNumber(value) ? '' : 'Invalid phone number';
    }
    if (field === 'city') {
      newErrors.city = value.length!=0 ? '' : 'Enter City';
    }
    if (field === 'area') {
      newErrors.area = value.length!=0 ? '' : 'Enter Area';
    }
    if (field === 'firstName') {
      newErrors.firstName = value.length!=0 ? '' : 'Enter First Name';
    }
    if (field === 'State') {
      newErrors.State = value.length!=0 ? '' : 'Enter State';
    }
    if (field === 'Pincode') {
      newErrors.Pincode = value.length!=0 ? '' : 'Enter Pincode';
    }

    setErrors(newErrors);
    setFormData({ ...formData, [field]: value });
  };

  const getTotalPrice = () => {
    return props.cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  // const totalPrice = getTotalPrice; // Replace with your actual total price
const callbackend = async(params) =>{
  try {
    const response = await axios.post('https://api.kanhaiyamart.com/checkout', params);

    // Navigate to the delivery-status.js page with the delivery status as a parameter
    if (response.status === 200) {
      props.setCart([])
      navigate(`/ordersuccess?order_id=${response.data.order_id}`);
    }
    else{
      window.alert(response.data.message);
    }

  } catch (error) {
    window.alert('Error in placing order!');
    console.error('Error fetching status:', error.response.data);
    // Handle the error, show an error message, etc.
  }
}

function loadScript(src) {
  return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
          resolve(true);
      };
      script.onerror = () => {
          resolve(false);
      };
      document.body.appendChild(script);
  });
}

const handlePayment = async (params) => {
  // Load Razorpay script
  await loadScript('https://checkout.razorpay.com/v1/checkout.js');

  const options = {
    key: process.env.REACT_APP_RAZOPAYKEY, // Replace with your Razorpay key
    amount: getTotalPrice()*100, // Replace with the amount in paisa (e.g., 100 for ₹1)
    currency: 'INR', // Replace with your preferred currency
    name: 'Kanhaiya Mart',
    description: 'Payment for your order',
    // image: 'your_logo_url.png', // Replace with your company logo URL
    // order_id: 'your_order_id', // Replace with the order ID generated on your server
    handler: function (response) {
      if (response.razorpay_payment_id) {
        // Call your backend with payment details
        params.online_payment_id=response.razorpay_payment_id
        callbackend(params);
      } else {
        alert('Payment unsuccessful');
      }
    },
    prefill: {
      name: formData.firstName+" "+formData.lastName, // Replace with the customer's name
      email: props.userInfo.email, // Replace with the customer's email
      contact: formData.phoneNumber, // Replace with the customer's phone number
    },
    theme: {
      color: '#2bba32', // Replace with your preferred color
    },
  };

  const razorpay = new window.Razorpay(options);
  razorpay.open();
}; 

  const handleCheckout = () => {
    // Implement your checkout logic here

    let newErrors = { ...errors };
    let valid=true;

    if(formData.firstName==''){
      newErrors.firstName = 'Enter First Name';
      valid=false;
    }
    if(errors.phoneNumber!=''){
      newErrors.phoneNumber = 'Enter Phone Number';
      valid=false;
    }
    if(formData.city==''){
      newErrors.city = 'Enter City';
      valid=false;
    }
    if(formData.area==''){
      newErrors.area = 'Enter Area';
      valid=false;
    }
    if(formData.State==''){
      newErrors.State = 'Enter State';
      valid=false;
    }
    if(formData.Pincode==''){
      newErrors.Pincode = 'Enter Pincode';
      valid=false;
    }
    if(!valid){
      setErrors(newErrors);
    }
    else{
    let params={
      user_id:props.userInfo.user_id,
      product_list:props.cart,
      total_amount:getTotalPrice(),
      user_name:formData.firstName+" "+formData.lastName,
      phone:formData.phoneNumber,
      city:formData.city,
      street:formData.street,
      area:formData.area,
      state:formData.State,
      pincode:formData.Pincode,
      buildingNumber:formData.buildingNumber,
      delivery_status:"Order Placed",
      mode_of_payment:paymentMode,
      online_payment_id:''
    }

    if(paymentMode=="Cash"){
      callbackend(params);
    }
    else{
      // call razopay
      handlePayment(params)
      console.log("razopay");
    }
    // console.log('Checkout:', formData);
    }
  };

  return (
    <Container style={{backgroundImage: `url(${BGImg})`,
    marginBottom:'50px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '50vh'}}>
      <Typography variant={window.innerWidth<1000? "h5":"h4"} align="center" style={{ margin: '20px 0', fontWeight: 'bold' }}>
        Checkout
      </Typography>

      <Grid container spacing={2}>
        {Object.keys(formData).map((field) => (
          <Grid item xs={12} sm={6} key={field}>
            <TextField
              label={field.replace(/([A-Z])/g, ' $1').toLowerCase()} // Convert camelCase to words
              fullWidth
              value={formData[field]}
              onChange={handleInputChange(field)}
              error={Boolean(errors[field])}
              helperText={errors[field]}
            />
          </Grid>
        ))}
        
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Mode of Payment</FormLabel>
            <RadioGroup
              aria-label="mode-of-payment"
              name="mode-of-payment"
              value={paymentMode}
              onChange={handlePaymentModeChange}
            >
              <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
              <FormControlLabel value="Online" control={<Radio />} label="Online" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Typography variant={window.innerWidth<1000? "subtitle1":"h6"} style={{ marginTop: '20px', fontWeight:'bold' }}>
        Total Price: ₹{getTotalPrice()}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        style={{ backgroundColor: '#2bba32', marginTop: '20px', fontWeight:'bold' }}
        onClick={handleCheckout}
      >
        Place Order
      </Button>
    </Container>
  );
};

export default CheckoutPage;
