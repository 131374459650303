import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BGImg from '../assets/hero-background.png'; // Adjust the path based on your project structure
import axios from 'axios';
import { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function AdminLogin(props) {
  const [open, setOpen] = useState(false);
  const [open_error, setOpenError] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });
  const navigate = useNavigate();

const handleLogin = () => {
  // Your login logic here
   props.setadminlogin(true)
  // Assuming the login is successful
  setOpen(true);

  // Redirect after 2 seconds
  setTimeout(() => {
    setOpen(false);
    navigate('/admin-main-page')
  }, 1000);
};

const handleClose = () => {
  setOpen(false);
};

const handleCloseError = () => {
  setOpenError(false);
};

  const handleSubmit = async(event) => {
    event.preventDefault();

    // Validation
    let valid = true;
    const newErrors = { email: '', password: '' };

    const formData = new FormData(event.currentTarget);

    if (formData.get('email').trim() === '') {
      newErrors.email = 'Email is required';
      valid = false;
    }

    if (formData.get('password').trim() === '') {
      newErrors.password = 'Password is required';
      valid = false;
    }

    if (valid) {
    const data = new FormData(event.currentTarget);
    try{ 
      if(data.get('email')==String(process.env.REACT_APP_EMAIL) && data.get('password')==String(process.env.REACT_APP_PASSWORD)){
        handleLogin()
      }
      else{
        setOpenError(true);
        
        // Redirect after 1 seconds
        setTimeout(() => {
            setOpenError(false);
        }, 2000);
      }
    } catch (error) {
      console.error('Login failed');
      // Handle login failure, show error message, etc.
    }
  } else {
    // Update errors and display error messages
    setErrors(newErrors);
  }

  };

  return (
    // <ThemeProvider theme={defaultTheme}>
    <div
      style={{
        backgroundImage: `url(${BGImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '80vh',
      }}
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Admin Login
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              error={!!errors.email}
              helperText={errors.email}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={!!errors.password}
              helperText={errors.password}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: '#2bba32',
                '&:hover': {
                  backgroundColor: '#2bba32', // Set the hover color to be the same as the default background color
                },
              }}
            >
              Login In
            </Button>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
        {/* Snackbar for displaying "successfully login" message */}
        <Snackbar
        open={open}
        autoHideDuration={2000} // Duration in milliseconds
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="success">
          Successfully logged in
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={open_error}
        autoHideDuration={2000} // Duration in milliseconds
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseError} severity="error">
          Error while login
        </MuiAlert>
      </Snackbar>
      </div>
    // </ThemeProvider>
  );
}