// OrderSuccessPage.js

import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import BGImg from '../assets/hero-background.png';

const OrderSuccessPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const order_id = searchParams.get('order_id');

  return (
    <div style={{ textAlign: 'center', marginTop: '80px', marginBottom: '50px', backgroundImage: `url(${BGImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '50vh' }}>
      <Typography variant={window.innerWidth<1000? "h6":"h4"} gutterBottom>
        Order Placed Successfully!
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Your order ID is: {order_id}
      </Typography>
      <Button
        variant="contained"
        style={{ backgroundColor: '#2bba32', color: '#fff', marginTop: '20px', marginBottom: "80px", fontWeight:'bold'}}
        component={Link}
        to="/trackshipping"
      >
        Track Your Order
      </Button>
    </div>
  );
};

export default OrderSuccessPage;
