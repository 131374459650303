import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button, Grid, Paper, TextField } from '@mui/material';
import BGImg from '../assets/hero-background.png'; // Adjust the path based on your project structure
import DeliveryBoyImage from '../assets/delivery.png'; // Adjust the path based on your project structure


const Home = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${BGImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '80vh',
        display: 'flex',
        flexDirection: window.innerWidth < 1000 ? 'column':'row', // Set the direction to row
        alignItems: 'center',
        justifyContent: 'space-around', // Adjust the space between text and image
        color: 'white'
      }}
    >
      {/* Left Side */}
      <div style={{ marginLeft: '10%', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '80%' }}>
        <Typography variant={window.innerWidth < 1000? "h6":"h4"}  style={{ fontWeight: 'bold', color: 'black', whiteSpace: window.innerWidth < 1000? 'normal':'nowrap' }} gutterBottom>
          Groceries and more in 60 minutes
        </Typography>
        <Typography variant={window.innerWidth < 1000? "subtitle4":"subtitle1"} style={{ fontWeight: 'bold', color: 'black' }} gutterBottom>
          Making on-demand retail a reality!
        </Typography>
        <Button variant="contained" style={{ backgroundColor: '#2bba32', color: 'white', marginTop: '10px', fontWeight: "bold", fontStyle: "Open Sans" }}         
        component={Link}
        to="/products">
          Shop Now
        </Button>
      </div>

      {/* Right Side */}
      <div style={{ marginLeft: '10%'}}>
        <img
          src={DeliveryBoyImage}
          alt="Delivery Boy"
          style={{ maxWidth: '70%', borderRadius: '8px' }} // Adjust styles as needed
        />
      </div>
    </div>
  );
};

export default Home;
