// ReturnPolicy.js

import React from 'react';
import { Container, Typography } from '@mui/material';

const ReturnPolicy = () => {
    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
                Return Policy
            </Typography>
            <Typography paragraph>
                We want you to be completely satisfied with your purchase. If you're not satisfied for any reason, you may return the item within 1 days of delivery for a full refund, exchange, or store credit.
            </Typography>
            <Typography paragraph>
                To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
            </Typography>
            <Typography paragraph>
                To initiate a return, please contact our customer service team at kanhaiyamart01@gmail.com with your order number and details about the product you would like to return. We will respond quickly with instructions for how to return items from your order.
            </Typography>
            <Typography paragraph>
                Please note that you will be responsible for return shipping costs unless the return is due to a mistake on our part (such as receiving the wrong item or a defective product).
            </Typography>
            <Typography paragraph>
                Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.
            </Typography>
            <Typography paragraph>
                If your return is approved, your refund will be processed, and a credit will automatically be applied to your original method of payment within 4-5 days.
            </Typography>
            <Typography paragraph>
                If you haven’t received a refund yet, first check your bank account again. Then contact your credit card company, it may take some time before your refund is officially posted.
            </Typography>
            <Typography paragraph>
                If you’ve done all of this and you still have not received your refund yet, please contact us at kanhaiyamart01@gmail.com.
            </Typography>
            <Typography paragraph>
                Sale items are final sale and cannot be returned or exchanged unless they are defective or damaged upon receipt.
            </Typography>
        </Container>
    );
};

export default ReturnPolicy;
