import React, { useState } from 'react';
import { Typography, Button, Card, CardContent, CardActions, CardMedia, IconButton, Container, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Apple from '../assets/apple.jpeg';
import { useNavigate } from 'react-router-dom';
import BGImg from '../assets/hero-background.png';

const CartPage = (props) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    if(props.isLoggedIn){
      navigate(`/checkout`);
    }
    else{
      navigate(`/login`);
    }
  }

  const handleRemoveItem = (id) => {
    props.setCart((prevCart) => prevCart.filter((item) => item.id !== id));
  };

  const handleQuantityChange = (id, value) => {
    props.setCart((prevCart) =>
      prevCart.map((item) => {
        if (item.id === id) {
          const newQuantity = item.quantity + value;
          // Check if the new quantity is less than 0
          if (newQuantity <= 0) {
            // If so, call handleRemoveItem to remove the item from the cart
            return { ...item, quantity: item.quantity };
          } else {
            // Otherwise, update the quantity
            return { ...item, quantity: newQuantity };
          }
        } else {
          return item;
        }
      })
    );
  };  

  const getTotalPrice = () => {
    return props.cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  return (
    <Container style={{marginTop:'5%', marginBottom:'50px', backgroundImage: `url(${BGImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '50vh'}}>
      <Typography variant={window.innerWidth<1000? "h5":"h4"} align="center" style={{fontWeight:'bold'}} gutterBottom>
        Shopping Cart
      </Typography>
      {props.cart.map((item) => (
        <Card key={item.id} sx={{ display: 'flex', flexDirection: window.innerWidth<1000? "column" : "row", marginBottom: 2 }}>
          <CardMedia component="img" alt={item.name} style={{width:'100px', height:'50px',objectFit: 'contain'}} image={item.image} />
          <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6">{item.name}</Typography>
            <Typography variant="body2">Price: ₹{item.price} / {item.unit}</Typography>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 'auto' }}>
              <IconButton onClick={() => handleQuantityChange(item.id, -1*item.changequantity )}>-</IconButton>
              <Typography>{item.quantity} {item.unit}</Typography>
              <IconButton onClick={() => handleQuantityChange(item.id, item.changequantity )}>+</IconButton>
              <IconButton onClick={() => handleRemoveItem(item.id)}>
                <DeleteIcon />
              </IconButton>
            </div>
          </CardContent>
          <CardActions>
            <Typography variant="subtitle1">Total: ₹{item.price * item.quantity}</Typography>
          </CardActions>
        </Card>
      ))}
      {props.cart.length != 0 ? (
        <>
      <Typography variant="h6" sx={{ marginTop: 2 }}>
        Total Price: ₹{getTotalPrice()}
      </Typography>
      <Button variant="contained" style={{ backgroundColor: '#2bba32', color: '#fff', marginTop: 6, fontWeight:'bold' }} onClick={handleOnClick}>
        Checkout
      </Button>
      </>
      ) : null}
      {props.cart.length == 0 ? (
        <Paper style={{ margin: '20px auto', padding: '20px', maxWidth: '600px', backgroundColor: '#fcfcfc', marginBottom:"200px" }}>
        <Typography variant={window.innerWidth<1000? "subtitle1":"h6"} gutterBottom style={{marginTop:'2%', color:'grey'}}>
           Empty(No item in the cart)
        </Typography>
        </Paper>
      ) : null}
    </Container>
  );
};

export default CartPage;
