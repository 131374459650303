// ForgetPassword.js
import React, { useState } from 'react';
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Typography, Box } from '@mui/material';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BGImg from '../assets/hero-background.png';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async () => {
    if(!validateEmail(email)){
        window.alert("Enter Correct Email!")
    }
    else{
    try {
      // Send email to the backend
      const response = await Axios.post('https://api.kanhaiyamart.com/forgetpassword', { email: email });

      // Handle the response and update the state accordingly
      setDialogContent(response.data.message);
    //   setOpenDialog(true);
      window.alert(response.data.message)
      navigate(`/resetpassword?email_id=${email}`);
    } catch (error) {
      // Handle errors
      setDialogContent('Error: Unable to send email');
      setOpenDialog(true);
    }
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="50vh"
    >
      <Typography variant={window.innerWidth < 1000 ? "h5":"h4"} style={{ fontWeight: 'bold', marginBottom: '20px', color: 'black' }}>
        Forget Password
      </Typography>
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        margin="normal"
        value={email}
        onChange={handleEmailChange}
        style={{maxWidth: window.innerWidth < 1000 ? '90%':'50%', marginBottom:'20px'}}
      />
      <Button
        variant="contained"
        style={{ backgroundColor: '#2bba32', color: 'white', fontSize: window.innerWidth < 1000 ? '12px':'', fontWeight:"bold" }}
        onClick={handleSubmit}
      >
        Send Email
      </Button>

      {/* Dialog for displaying response */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Forget Password</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for displaying errors */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="Error: Unable to send email"
      />
    </Box>
  );
};

export default ForgetPassword;
