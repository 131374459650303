import React, { useState, useEffect } from 'react';
import { AppBar, Container, CssBaseline, Toolbar, Typography, Grid, Paper, Button, ButtonGroup, TextField } from '@mui/material';
import BGImg from '../assets/hero-background.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const categories = process.env.REACT_APP_CATEGORIES.split(',');

const AdminProductPage = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');

    useEffect(() => {
        // Function to fetch products based on category
        const fetchProducts = async () => {
          try {
            const response = await axios.get('https://api.kanhaiyamart.com/products');
    
            if(response.status==200){
            // Set the fetched products in the state
            setProducts(response.data.products);
            }
            else{
                console.log("Error in fetching Orders")
            }
          } catch (error) {
            console.error('Error fetching products:', error.response.data);
            // Handle the error, show an error message, etc.
          }
        };
    
        // Call the fetchProducts function when the component mounts or when the category changes
        fetchProducts();
      }, []); // Dependency array ensures the effect runs when the category changes

    const applyFilters = (product) => {
      // Apply category filter
      const isCategoryMatch = selectedCategory === 'All' || product.category === selectedCategory;
  
      // Apply search filter
      const isSearchMatch =
        !searchTerm || product.name.toLowerCase().includes(searchTerm.toLowerCase())
        || String(product.id).includes(searchTerm.toLowerCase()) ;
        
      return isCategoryMatch && isSearchMatch;
    };

    const productsPerPage = 40;
    const [currentPage, setCurrentPage] = useState(1);
    const filteredProducts = selectedCategory === 'All' ? products.filter(applyFilters) : products.filter(applyFilters).filter(product => product.category === selectedCategory);
    const paginatedProducts = filteredProducts.slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage);
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    const handleCartAction = (productId) => {
      navigate(`/admin-update-product?product_id=${productId}`);
    };
  
    return (
      <div>
        <CssBaseline />
        <Container style={{ backgroundImage: `url(${BGImg})`, backgroundSize: 'cover', marginTop: '40px', marginBottom: '40px' }}>
        {/* Search bar */}
        <Typography variant="h6" style={{ fontWeight: 'bold', color: 'black' }}>Select Product to be Updated:</Typography>
        <TextField
          label="Search Product"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
          <Typography variant="h6" style={{ fontWeight: 'bold', color: 'black' }}>Product Categories:</Typography>
          <ButtonGroup style={{
            backgroundColor: '#fff',
            paddingTop: '10px',
            display: 'flex',
            flexDirection: window.innerWidth < 1000 ? 'column' : 'row',
          }}>
            {categories.map(category => (
              <Button
                key={category}
                variant={selectedCategory === category ? 'contained' : 'outlined'}
                style={{ color: 'black', fontWeight: 'bold' }}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </Button>
            ))}
          </ButtonGroup>
          <Grid container spacing={3} style={{ marginTop: '20px' }}>
            {paginatedProducts.map(product => (
              <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
                <Paper style={{ padding: '10px', textAlign: 'center', border: '1px solid grey', borderRadius: '15px', backgroundColor: '#fcfcfc' }}>
                  <img src={product.image} alt={product.name} style={{ width: '120px', height: '120px', objectFit: 'contain', border: '0.5px solid grey', borderRadius: '10px' }} />
                  <Typography variant="subtitle1">{product.name}</Typography>
                  <Typography variant="body2">Quantity: {product.quantity+" "+product.unit}</Typography>
                  <Typography variant="body1">Price: ₹{product.price}</Typography>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#2bba32', color: 'white', fontWeight:'bold' }}
                    onClick={() => handleCartAction(product.id)}
                  >
                    Update the product
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index + 1}
                variant={currentPage === index + 1 ? 'contained' : 'outlined'}
                style={{ margin: '0 5px' }}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </Button>
            ))}
          </div>
        </Container>
      </div>
    );
  };
  
  export default AdminProductPage;