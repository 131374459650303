import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import {
  Button
} from "@mui/material";

const AdminNavbar = (props) => {
    const navigate = useNavigate();
    const handleLogout = () => {
        // Perform logout logic here
        props.setadminlogin(false);
        navigate('/adminlogin')
      };

      const handlelogoclick = () => {
        // Perform logout logic here
        if(props.adminlogin){
            navigate('/admin-main-page')
        }
        else{
            navigate('/adminlogin')
        }
      };

  return (
    <AppBar position="static" style={{ backgroundColor: '#2bba32'}}>
        <div style={{ display: "flex", alignItems: "center" }}>
      <Toolbar>
      <Button onClick={handlelogoclick} style={{ textDecoration: "none", color: "inherit" }}>
        <Typography variant={window.innerWidth<1000? "subtitle1": "h6"} style={{fontWeight:'bold'}}>Kanhaiya Mart Admin Panel</Typography>
      </Button>
      </Toolbar>
        {props.adminlogin? (
        <Button
                onClick={handleLogout}
                style={{ textDecoration: "none", color: "inherit", fontWeight:'bold', marginLeft:window.innerWidth<1000? '10%':'60%' }}
              >
                Logout
              </Button>):null}
            </div>
    </AppBar>
  );
};

export default AdminNavbar;
