import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const MainPage = () => {
  const navigate = useNavigate();

  const handleButtonClick = (route) => {
    navigate(route);
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '100px', display:'flex', flexDirection:'column', alignItems: 'center' }}>
      <Button
        variant="contained"
        style={{ backgroundColor: '#2bba32', margin: '10px', fontWeight:'bold', maxWidth:'50%' }}
        onClick={() => handleButtonClick('/admin-add-product')}
      >
        Add a New Product
      </Button>

      <Button
        variant="contained"
        style={{ backgroundColor: '#2bba32', margin: '10px', fontWeight:'bold', maxWidth:'50%' }}
        onClick={() => handleButtonClick('/admin-show-products')}
      >
        Edit Existing Product
      </Button>

      <Button
        variant="contained"
        style={{ backgroundColor: '#2bba32', margin: '10px', fontWeight:'bold', maxWidth:'50%' }}
        onClick={() => handleButtonClick('/admin-show-orders')}
      >
        Show Orders to be Delivered
      </Button>

    </div>
  );
};

export default MainPage;
