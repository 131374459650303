import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText, Divider, Paper, Button } from '@mui/material';
import axios from 'axios';
import BGImg from '../assets/hero-background.png';

const OrdersPage = (props) => {

    const [orderList, setorderList] = useState([])
    const [refreshPage, setRefreshPage] = useState(false);

    useEffect(() => {
        // Function to fetch orders
        const fetchmyorders = async () => {
          try {
            const response = await axios.post('https://api.kanhaiyamart.com/myorders',
            {user_id:props.userInfo.user_id});
    
            // Set the fetched orders list in the state
            if(response.status==200){
            setorderList(response.data.orders);
            }
            else{
                console.error('Error fetching order List');
            }
          } catch (error) {
            console.error('Error fetching order List:', error.response.data);
            // Handle the error, show an error message, etc.
          }
        };
    
        // Call the fetchProducts function when the component mounts or when the category changes
        fetchmyorders();
        setRefreshPage(false);
      }, [refreshPage]); // Dependency array ensures the effect runs when the category changes

      const handleGetStatusClick = async(orderId) => {
        try {
          const response = await axios.post('https://api.kanhaiyamart.com/cancelorder', {
            order_id: orderId
          });
          if (response.data.success) {
            window.alert('Order Cancelled successfully. If you have paid online, you will receive a refund within 3-4 working days!');
            setRefreshPage(true);
          }
          else{
            window.alert(response.data.message)
          }
    
        } catch (error) {
          console.error('Error fetching status:', error.response.data);
          // Handle the error, show an error message, etc.
        }
      };
    
  return (
    <div style={{ textAlign: 'center', padding: '20px', marginBottom: orderList.length==0 ? '22%':'5%', backgroundImage: `url(${BGImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '50vh',
    marginBottom: '50px' }}>
      <Typography variant={window.innerWidth<1000? "h6":"h5"} gutterBottom>
        My Orders
      </Typography>
      {orderList.map(order => (
        <Paper key={order.order_id} style={{ margin: '20px auto', padding: '20px', maxWidth: '600px', backgroundColor: '#fcfcfc' }}>
          <Typography variant={window.innerWidth<1000? "subtitle1":"h6"} gutterBottom>
            Order ID: {order.order_id}
          </Typography>
          <List>
            {order.product_list.map(product => (
              <ListItem key={product.name} style={{ justifyContent: 'space-between' }}>
                <ListItemText primary={product.name} secondary={`Quantity: ${product.quantity+" "+product.unit}`} />
              </ListItem>
            ))}
          </List>
          <Divider style={{ margin: '16px 0' }} />
          <Typography variant={window.innerWidth<1000? "subtitle2":"subtitle1"} gutterBottom>
            Total Amount: ₹{order.total_amount}
          </Typography>
          <Typography variant={window.innerWidth<1000? "subtitle2":"subtitle1"}>
            Status: {order.delivery_status ? order.delivery_status : 'Order Placed'}
          </Typography>
          <Typography variant={window.innerWidth<1000? "subtitle2":"subtitle1"}>
            Payment Method: {order.mode_of_payment}
          </Typography>
          {/* && order.delivery_status.toLowerCase() === 'order placed' */}
          {order.delivery_status.toLowerCase() != 'cancelled' ? (
          <Button
          variant="contained"
          style={{ backgroundColor: 'red', color: '#fff', width: window.innerWidth < 1000 ? '90%':'50%', marginBottom: window.innerWidth < 1000 ? '50%': '5%', fontSize: window.innerWidth < 1000 ? '12px':'', fontWeight:"bold" }}
          onClick={() => handleGetStatusClick(order.order_id)}
          >
          Cancel Order
          </Button>
          ) : null}
        </Paper>
      ))}
        {orderList.length == 0 ? (
        <Paper style={{ margin: '20px auto', padding: '20px', maxWidth: '600px', backgroundColor: '#fcfcfc', marginBottom:'200px' }}>
        <Typography variant={window.innerWidth<1000? "h6":"h5"} gutterBottom style={{marginTop:'2%', color:'grey'}}>
           Empty(No order history)
        </Typography>
        </Paper>
      ) : null}
    </div>
  );
};

export default OrdersPage;
